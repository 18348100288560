import React from "react";
import ScheduleItem from "./ScheduleItem";
import Loading from "../../misc/Loading";
import AllDone from "../../splitsies/AllDone";
import ItemWithHeader from "../../misc/ItemWithHeader"
import { Table, TableBody, TableContainer } from "@mui/material";
import _ from "lodash";

export default function TasksList({ title, user, isLoading, data }) {

    if (isLoading) {
        return <Loading />
    }

    const elements = data?.map((task) => (
        <ScheduleItem user={user} task={task} />
    ))

    return (
        <ItemWithHeader
            title={title}
            emptyElement={<AllDone text={"Everything done."} />}
            elementsNotPresent={_.isEmpty(data)}
            elements={
                <TableContainer>
                    <Table>
                        <TableBody>
                            {elements}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        />
    );
}
