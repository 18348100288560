import React, { useContext, useState, useEffect, useCallback } from "react";
import { parseJwt } from "./../misc/Helper";
import { setItem, getItem, removeItem } from "../misc/SessionStorageHelper";
import User from "./auth/User";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { authService } from '../../services/user/AuthService';

const AuthContext = React.createContext({
  isAuthenticated: false,
});

function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setAuthenticated] = useState(false);
  const queryClient = useQueryClient();

  useEffect(() => {
    const initializeAuth = async () => {
      const storedAuthInformation = JSON.parse(getItem("token"));

      if (storedAuthInformation) {
        const token = parseJwt(storedAuthInformation.token);

        if (token && token.exp) {
          // Check if the token is expired
          if (Date.now() > token.exp * 1000) {
            const response = await authService.refreshToken(storedAuthInformation.refresh_token);
            setCurrentAuthData(response);
          } else {
            setUser(storedAuthInformation);
            setAuthenticated(true);
          }
        }
        else {
          removeUser();
        }
      }
      setLoading(false);
    };

    initializeAuth();
  }, []);

  const loginMutation = useMutation({
    mutationFn: authService.login,
    onSuccess: (data) => {
      setCurrentAuthData(data);
    },
  });

  const signupMutation = useMutation({
    mutationFn: authService.register,
    onSuccess: (data) => {
      setCurrentAuthData(data);
    },
  });

  const logoutMutation = useMutation({
    mutationFn: authService.logout,
    onSuccess: () => {
      removeUser();
    },
  });

  const logout = () => {
    logoutMutation.mutateAsync();
  };

  const setCurrentAuthData = useCallback((response) => {
    const parsedData = parseJwt(response.data.access_token);
    const user = new User(
      parsedData.sub,
      parsedData.sub,
      response.data.access_token,
      response.data.refresh_token
    );

    setUser(user);
    setItem("token", JSON.stringify(user));
    setAuthenticated(true);
  }, []);

  const removeUser = useCallback(() => {
    queryClient.cancelQueries();
    queryClient.invalidateQueries();
    removeItem("token");
    setUser(null);
    setAuthenticated(false);
  }, [queryClient]);

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, loading, loginMutation, signupMutation, logout, removeUser }}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContext;

export function useAuth() {
  return useContext(AuthContext);
}

export { AuthProvider };
