import React, { useState } from "react";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { overheadsService } from "../../services/splitsies/OverheadsService";
import { personDataService } from "../../services/user/PersonsService";
import DropdownSelect from "../misc/DropdownSelect";
import { TextField, Box, Chip } from "@mui/material";
import Table from "../misc/Table";
import MultiSelect from "./MultiSelect";

export default function OverheadsTable() {
  const queryClient = useQueryClient();

  const { isLoading, data } = useQuery({
    queryKey: ["overheads.table.data"],
    queryFn: () => overheadsService.getAll().then((res) => res.data),
  });

  const { data: personData } = useQuery({
    queryKey: ["common.personData"],
    queryFn: () => personDataService.getAllNames().then((res) => res.data),
    placeholderData: [],
  });

  const { data: frequencies } = useQuery({
    queryKey: ["overheads.frequencies"],
    queryFn: () => overheadsService.getFrequencies().then((res) => res.data),
    placeholderData: [],
  });

  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  const years = Array.from({ length: 5 }, (_value, index) => new Date().getFullYear() + index);

  const deleteMutation = useMutation({
    mutationFn: (id) => overheadsService.delete(id),
    onSuccess: () => queryClient.invalidateQueries(["overheads.table.data"]),
  });

  const editMutation = useMutation({
    mutationFn: (updatedRow) => overheadsService.save(updatedRow),
    onSuccess: () => queryClient.invalidateQueries(["overheads.table.data"]),
  });

  const [editRowId, setEditRowId] = useState(null);
  const [editRowData, setEditRowData] = useState({});
  const [isCreatingRow, setIsCreatingRow] = useState(false); // Track if a new row is being created

  const handleEditClick = (row) => {
    setEditRowId(row.id);
    setEditRowData(row); // Set the current row data to edit
  };

  const handleSaveEdit = () => {
    if (editRowId === "new") {
      // Handle the creation of a new row
      editMutation.mutate(editRowData);
    } else {
      // Handle saving an existing row
      editMutation.mutate(editRowData);
    }
    setEditRowId(null); // Exit edit mode
    setIsCreatingRow(false); // Stop creating new row mode
  };

  const handleCancelEdit = () => {
    setEditRowId(null); // Cancel edit mode
    setIsCreatingRow(false); // Cancel new row creation mode
  };

  const handleDelete = (row) => {
    deleteMutation.mutate(row.id);
  };

  const handleCreateNewRowClick = () => {
    setIsCreatingRow(true);
    setEditRowId("new"); // Mark the row as new
    setEditRowData({ name: "", price: "", payingPerson: "", splittingPersons: [], frequency: "", validUntilMonth: "", validUntilYear: "", cancelled: false });
  };

  // Function to check if a row is being edited
  const isEditingRow = (id) => editRowId === id;

  const columns = React.useMemo(
    () => [
      {
        header: "Name",
        accessorKey: "name",
        renderCell: (row) =>
          isEditingRow(row.id) ? (
            <TextField
              fullWidth
              value={editRowData.name || ""}
              onChange={(e) => setEditRowData({ ...editRowData, name: e.target.value })}
            />
          ) : (
            row.name
          ),
      },
      {
        header: "Amount",
        accessorKey: "price",
        renderCell: (row) =>
          isEditingRow(row.id) ? (
            <TextField
              fullWidth
              type="number"
              value={editRowData.price || ""}
              onChange={(e) => setEditRowData({ ...editRowData, price: e.target.value })}
            />
          ) : (
            `€${row.price.toFixed(2)}`
          ),
      },
      {
        header: "Paid By",
        accessorKey: "payingPerson",
        renderCell: (row) =>
          isEditingRow(row.id) ? (
            <DropdownSelect
              options={personData}
              value={editRowData.payingPerson}
              onChange={(selectedValue) => {
                setEditRowData({ ...editRowData, payingPerson: selectedValue });
              }}
            />
          ) : (
            row.payingPerson
          ),
      },
      {
        header: "Split By",
        accessorKey: "splittingPersons",
        renderCell: (row) =>
          isEditingRow(row.id) ? (
            <MultiSelect
              data={personData || []}
              value={editRowData.splittingPersons || []}
              onChange={(selectedValues) =>
                setEditRowData({ ...editRowData, splittingPersons: selectedValues })
              }
            />
          ) : (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {row.splittingPersons.map((it) => (
                <Chip key={it} label={it} />
              ))}
            </Box>
          ),
      },
      {
        header: "Frequency",
        accessorKey: "frequency",
        renderCell: (row) =>
          isEditingRow(row.id) ? (
            <DropdownSelect
              options={frequencies}
              value={editRowData.frequency}
              onChange={(selectedValue) => {
                setEditRowData({ ...editRowData, frequency: selectedValue });
              }}
            />
          ) : (
            row.frequency
          ),
      },
      {
        header: "Until Month",
        accessorKey: "validUntilMonth",
        renderCell: (row) =>
          isEditingRow(row.id) ? (
            <DropdownSelect
              options={monthNames}
              value={editRowData.validUntilMonth}
              onChange={(selectedValue) => {
                setEditRowData({ ...editRowData, validUntilMonth: selectedValue });
              }}
            />
          ) : (
            row.validUntilMonth
          ),
      },
      {
        header: "Until Year",
        accessorKey: "validUntilYear",
        renderCell: (row) =>
          isEditingRow(row.id) ? (
            <DropdownSelect
              options={years}
              value={editRowData.validUntilYear}
              onChange={(selectedValue) => {
                setEditRowData({ ...editRowData, validUntilYear: selectedValue });
              }}
            />
          ) : (
            row.validUntilYear
          ),
      },
    ],
    [editRowId, editRowData, personData]
  );

  const renderSummary = (row) => (
    <>
      <Chip label={`€${row.price}`} variant="outlined" />
      <Chip label={`${row.payingPerson}`} variant="outlined" />
      <Chip label={`${row.frequency}`} variant="outlined" />
    </>
  );

  const renderStatus = (row) => {
    return (
      <Chip color={row.cancelled ? "error" : "success"}
        label={row.cancelled ? "Cancelled" : "Active"}
        variant="outlined" />
    );
  };

  return (
    <Table
      name={"Overheads"}
      columns={columns}
      data={data}
      isLoading={isLoading}
      onDelete={handleDelete} // Pass delete handler
      onEditClick={handleEditClick} // Pass edit handler
      onSaveEdit={handleSaveEdit} // Pass save handler
      onCancelEdit={handleCancelEdit} // Pass cancel handler
      isEditingRow={isEditingRow} // Pass the function to check edit mode
      isCreatingRow={isCreatingRow} // Pass the flag for creating new row
      onCreateNewRowClick={handleCreateNewRowClick} // Pass the function for creating a new row
      editRowData={editRowData} // Pass edit row data
      setEditRowData={setEditRowData} // Pass setter for edit row data
      renderSummary={renderSummary} // Pass summarized info here
      renderStatus={renderStatus}
    />
  );
}
