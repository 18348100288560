import instance from "../Axios";

class PersonService {

    save(data) {
        return instance.post(`/persons/`, data);
    }

    getCurrentCastle(name) {
        return instance.get(`/persons/${name}/castle`);
    }

    getAllNames() {
        return instance.get(`/persons/names`);
    }

}

export let personDataService = new PersonService();