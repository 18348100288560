import { useQuery } from "@tanstack/react-query";
import { castleService } from "../../../services/castle/CastleService";
import { useEffect, useState } from "react";
import Chart from "../../misc/Chart";

export default function WaterAnalysisChart({loading, currentCastle}) {
  const [dates, setDates] = useState([]);
  const [coldConsPerMonth, setColdConsPerMonth] = useState([]);
  const [warmConsPerMonth, setWarmConsPerMonth] = useState([]);

  const { isLoading, data } = useQuery({
    queryKey: ["castle.water.data"],
    queryFn: () => castleService.getWaterForCastle(currentCastle).then((res) => res.data),
  });
  
  useEffect(() => {
    const fetchData = () => {
      if (isLoading) {
        return;
      }

      if (data) {
        setDates(data.map((data) => data.recordDate));
        setColdConsPerMonth(data.map((data) => data.coldConsumptionPerMonth));
        setWarmConsPerMonth(data.map((data) => data.warmConsumptionPerMonth));
      }
    };

    fetchData();
  }, [loading, isLoading, data]);

  const series = [
    {
      data: warmConsPerMonth,
      label: "Warm Consumption Per Month",
    },
    {
      data: coldConsPerMonth,
      label: "Cold Consumption Per Month",
    },
  ];

  return <Chart name={"Water Analysis"} dates={dates} series={series} />;
}
