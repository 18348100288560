import React from "react";

import PageWithSidebar from "../../misc/PageWithSidebar";
import ElectricityTable from "./ElectricityTable";
import ElectricitySidebar from "./ElectricitySidebar";

export default function Electricity() {
  return (
    <PageWithSidebar
      page={<ElectricityTable />}
      sidebar={<ElectricitySidebar />}
    />
  );
}
