import {Box, Menu, MenuItem, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import * as React from "react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import {stringAvatar} from "../StringColorHelper";

function UserMenu({user, isAuthenticated, logout}) {
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <Box sx={{flexGrow: 0}}>
            <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                    <Avatar {...stringAvatar(user)} />
                </IconButton>
            </Tooltip>
            <Menu
                sx={{mt: "45px"}}
                id="menu-appbar"
                anchorOrigin={{vertical: "top", horizontal: "right"}}
                keepMounted
                transformOrigin={{vertical: "top", horizontal: "right"}}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                {isAuthenticated ? (
                    <Box>
                        <MenuItem component={Link} to="/settings">
                            <Typography textAlign="center">Settings</Typography>
                        </MenuItem>
                        <MenuItem component={Link} to="/" onClick={logout}>
                            <Typography textAlign="center">Logout</Typography>
                        </MenuItem>
                    </Box>
                ) : (
                    <Box>
                        <MenuItem component={Link} to="/login">
                            <Typography textAlign="center">Login</Typography>
                        </MenuItem>
                        <MenuItem component={Link} to="/signup">
                            <Typography textAlign="center">Sign Up</Typography>
                        </MenuItem>
                    </Box>
                )}
            </Menu>
        </Box>
    );
}

export default UserMenu;
