import React from "react";
import ItemWithHeader from "./ItemWithHeader";

export default function Sidebar({ elements, emptyElement, name }) {
  return (
    <ItemWithHeader
      title={name}
      emptyElement={emptyElement}
      elements={elements}
    />
  );
}
