import React from "react";
import { useQuery } from "@tanstack/react-query";
import Loading from "../misc/Loading";
import { overheadsService } from "../../services/splitsies/OverheadsService";
import OwedOverheadList from "./OwedOverheadList";

export default function OverheadsInformationSidebar() {
  const { isLoading, data } = useQuery({
    queryKey: ["overheads.information"], queryFn: () => overheadsService.getMonthlyPayments().then((res) => res.data),
  });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <OwedOverheadList title={"Monthly Payments"} isLoading={isLoading} data={data} />
  );
}
