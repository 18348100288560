import { useQuery } from "@tanstack/react-query";
import { castleService } from "../../../services/castle/CastleService";
import { useEffect, useState } from "react";
import Chart from "../../misc/Chart";

export default function ElectricityAnalysisChart({ loading, currentCastle }) {
  const [dates, setDates] = useState([]);
  const [consPerDay, setConsPerDay] = useState([]);
  const [consPerMonth, setConsPerMonth] = useState([]);

  const { isLoading, data } = useQuery({
    queryKey: ["castle.electricity.data"],
    queryFn: () =>
      castleService.getElectricityForCastle(currentCastle).then((res) => res.data),
  });

  useEffect(() => {
    const fetchData = () => {
      if (isLoading) {
        return;
      }

      if (data) {
        setDates(data.map((data) => data.recordDate));
        setConsPerDay(data.map((data) => data.consumptionPerDay));
        setConsPerMonth(data.map((data) => data.consumptionPerMonth));
      }
    };

    fetchData();
  }, [loading, isLoading, data]);

  const series = [
    {
      data: consPerDay,
      label: "Consumption Per Day",
    },
    {
      data: consPerMonth,
      label: "Consumption Per Month",
    },
  ];

  return <Chart name={"Electricity Analysis"} dates={dates} series={series} />;
}
