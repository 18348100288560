import React from "react";
import { useQuery } from "@tanstack/react-query";
import { taskService } from "../../services/schedule/TaskService";
import { useAuth } from "../context/AuthContext";
import TasksList from "./schedule/TasksList";
import Loading from "../misc/Loading";
import _ from "lodash";

export default function UserSchedule() {

    const { user } = useAuth();

    const { isLoading: isLoadingDueForPersonData, data: dueForPersonData } = useQuery({
        queryKey: ["tasks", user.personName],
        queryFn: () => taskService.getTasksDueThisWeekForPerson(user.personName).then((res) => res.data),
        enabled: !!user,
    });

    const { isLoading: isLoadingOverdues, data: overduesData } = useQuery({
        queryKey: ["tasks.overdue"],
        queryFn: () => taskService.getOverdues().then((res) => res.data),
    });

    if (_.isEmpty(user)) {
        return <Loading />
    }

    return (
        <>
            <TasksList title={"Overdues"} user={user} isLoading={isLoadingOverdues} data={overduesData} />
            <TasksList title={"Current Tasks"} user={user} isLoading={isLoadingDueForPersonData} data={dueForPersonData} />
        </>    
    );
}
