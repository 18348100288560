import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { handleLogError } from "../misc/Helper";
import {
  Alert,
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { boxHeader, boxHeaderTitle } from "../Styles";

function Signup() {
  const { isAuthenticated, signupMutation } = useAuth();

  const [name, setName] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const user = { name, password };

    try {
      await signupMutation.mutateAsync(user);

      navigate(`/`);
    } catch (error) {
      handleLogError(error);
    }
  };

  let navigate = useNavigate();

  if (isAuthenticated) {
    navigate(`/`);
  }

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Box
        sx={{
          boxShadow: 2,
          borderRadius: "8px",
          width: "450px",
        }}
      >
        <Paper sx={boxHeader}>
          <Typography variant="h5" sx={boxHeaderTitle}>
            Sign Up
          </Typography>
        </Paper>

        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{ mt: 1, padding: "10px" }}
        >
          <Stack spacing={2} sx={{ marginBottom: 4 }}>
            <TextField
              type="text"
              icon="user"
              variant="outlined"
              label="Username"
              onChange={(e) => setName(e.target.value)}
              value={name}
              fullWidth
              required
            />
            <TextField
              type="password"
              icon="lock"
              variant="outlined"
              label="Password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              fullWidth
              required
            />
          </Stack>
          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 2, mb: 2 }}
            type="submit"
          >
            Sign Up
          </Button>
          {signupMutation.isError ? (
            <div>
              <Alert severity="error">
                Could not register. Please try again later. 
                Error: {signupMutation.error.message}.
              </Alert>
            </div>
          ) : null
          }
          {signupMutation.isSuccess ? <div>Signed up!</div> : null}
        </Box>
      </Box>
    </Grid>
  );
}

export default Signup;
