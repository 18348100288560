import React from "react";

import WaterTable from "./WaterTable";
import WaterInformationSidebar from "./WaterInfoSidebar";
import PageWithSidebar from "../../misc/PageWithSidebar";

export default function Water() {
  return (
    <PageWithSidebar
      page={<WaterTable />}
      sidebar={<WaterInformationSidebar />}
    />
  );
}
