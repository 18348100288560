import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import Table from "../../misc/Table";
import { electricityService } from '../../../services/castle/ElectricityService';
import { castleService } from "../../../services/castle/CastleService";
import { personDataService } from "../../../services/user/PersonsService";
import { useAuth } from "../../context/AuthContext";
import DropdownSelect from "../../misc/DropdownSelect";
import CustomDatePicker from "../../misc/CustomDatePicker";
import React from "react";

export default function ElectricityTable() {
  const { user } = useAuth();
  const queryClient = useQueryClient();

  const { isLoading, data } = useQuery({
    queryKey: ["electricity.readings.table.data"],
    queryFn: () => electricityService.getAll().then((res) => res.data),
  });

  const { data: allCastles } = useQuery({
    queryKey: ["castles.all"],
    queryFn: () => castleService.getAll().then((res) => res.data),
    placeholderData: [],
  });

  const { data: currentCastle } = useQuery({
    queryKey: ["persons.castles.current"],
    queryFn: () =>
      personDataService.getCurrentCastle(user.name).then((res) => res.data),
    placeholderData: "",
  });

  const columns = [
    {
      title: "Reading Date",
      field: "readingDate",
      type: "date",
      editComponent: (props) => {
        return (
            <CustomDatePicker
                label={"Upcoming Date"}
                error={props.error}
                helperText={props.helperText}
                value={props.value}
                onChange={props.onChange}
            />
        );
      },
    },
    {
      title: "Reading",
      field: "reading",
      type: "numeric",
    },
    {
      title: "Castle",
      field: "castle",
      initialEditValue: currentCastle,
      editComponent: (props) => (
        <DropdownSelect
          options={allCastles.map((it) => it.name)}
          label={"Castle"}
          value={props.value}
          originalError={props.error}
          currentHelperText={props.helperText}
          onChange={props.onChange}
        />
      ),
    },
  ];

  const editableOptions = {
    onRowAdd: (newData) => upsertMutation.mutateAsync({ newData }),
    onRowUpdate: (newData) => upsertMutation.mutateAsync({ newData }),
    onRowDelete: (oldData) => deleteMutation.mutateAsync({readingDate: oldData.readingDate, castleName: oldData.castle}),
  };

  const upsertMutation = useMutation({
    mutationFn: (input) => {
      electricityService.save(input.newData);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("electricity.readings.table.data");
      await queryClient.invalidateQueries("electricity.readings.information");
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (id) => electricityService.delete(id),
    onSuccess: async () => {
      await queryClient.invalidateQueries("electricity.readings.table.data");
      await queryClient.invalidateQueries("electricity.readings.information");
    },
  });

  return (
    <Table
      name={"Electricity Readings"}
      editableOptions={editableOptions}
      columns={columns}
      isLoading={isLoading}
      data={data}
    />
  );
}
