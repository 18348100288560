import * as React from "react";
import {Link} from "react-router-dom";
import Button from "@mui/material/Button";

function DesktopNavbarButton({name, link, icon}) {

    return (
        <Button
        variant="contained"
        color="primary"
        component={Link}
        to={link}
        sx={{
            margin: 1
        }}
    >
        {icon}
        {name}
    </Button>
    );
}

export default DesktopNavbarButton;
