import React from "react";
import PageWithSidebar from "../../misc/PageWithSidebar";
import AnalysisSidebar from "./AnalysisSidebar";
import Charts from "./Charts";

export default function Analysis() {

  return (
    <PageWithSidebar
      page={<Charts />}
      sidebar={<AnalysisSidebar />}
    />
  );
}
