import React from "react";
import { Paper, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export default function ListItem({ content }) {
  const theme = useTheme();

  return (
    <Paper
      sx={{
        position: "relative",
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        boxShadow: theme.shadows[2],
        borderRadius: "10px",
        margin: "8px",
        padding: "12px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        minHeight: "100px",
        border: `1px solid ${theme.palette.divider}`,
      }}
    >
      <Box sx={{ marginTop: 2 }} >
        {content}
      </Box>
    </Paper>
  );
}
