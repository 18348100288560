import * as React from "react";
import DropdownNavbarButton from './DropdownNavbarButton';
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';

export default function SplitsiesDropdownNavbarButton() {
    const menuItems = [
        {
            name: "Splitsies",
            link: "/splitsies",
            icon: <MonetizationOnIcon sx={{ marginRight: 1 }} />,
        },
        {
            name: "Overheads",
            link: "/overheads",
            icon: <CurrencyExchangeIcon sx={{ marginRight: 1 }} />,
        },
    ];

    return (
        <DropdownNavbarButton
            name="Splitsies"
            icon={<MonetizationOnIcon sx={{ marginRight: 1 }} />}
            menuItems={menuItems}
        />
    );
}
