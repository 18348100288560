import instance from "../Axios";

class AuthService {

    register(data) {
        return instance.post(`/auth/register`, data);
    }
    
    login(data) {
        return instance.post(`/auth/login`, data);
    }
    
    refreshToken() {
        return instance.post(`/auth/refresh-token`);
    }

    logout() {
        return instance.post(`/auth/logout`);
    }

    changePassword(data) {
        return instance.patch(`/users/change-password`, data);
    }
}

export let authService = new AuthService();