import React, { useState } from "react";
import { TableCell, TableRow, Checkbox } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTheme } from "@mui/material/styles";
import BeenhereIcon from '@mui/icons-material/Beenhere';
import { overheadsService } from "../../services/splitsies/OverheadsService";

export default function OverheadItem({ item }) {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const [removed, setRemoved] = useState(false);

  const removeMutation = useMutation({
    mutationFn: (id) => {
      return overheadsService.delete(id);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("overheads.table.data");
      await queryClient.invalidateQueries("overheads.information");
    },
  });

  const handleCheckboxChange = async () => {
    await removeMutation.mutateAsync(item.id);
    setRemoved(true);
  };

  if (removed) {
    return null; // Hide the row completely when removed
  }

  return (
    <TableRow
      sx={{
        '&:last-child td, &:last-child th': { borderBottom: 0 },
        textDecoration: removed ? "line-through" : "none",
        opacity: removed ? 0.5 : 1,
      }}
    >
      <TableCell align="center">{item.name}</TableCell>
      <TableCell align="center">{item.payingPerson}</TableCell>
      <TableCell align="center">€{item.price.toLocaleString()}</TableCell>
      <TableCell align="center">{item.frequency}</TableCell>
      <TableCell align="center">
        <Checkbox
          checked={removed}
          onChange={handleCheckboxChange}
          icon={<BeenhereIcon />}
          checkedIcon={<BeenhereIcon />}
          sx={{
            transform: "scale(1.5)",
            color: theme.palette.primary.main,
            '&.Mui-checked': {
              color: theme.palette.primary.main,
            },
          }}
        />
      </TableCell>
    </TableRow>
  );
}
