// eslint-disable-next-line no-unused-vars
import React from 'react'
import { Box, SvgIcon, Typography } from '@mui/material';
import { styled } from "@mui/system";

export default function NoEntry({ icon, text }) {

  const LargeIcon = styled(SvgIcon)(({ theme }) => ({
    fontSize: 60,
    marginBottom: theme.spacing(1), 
    color: theme.palette.text.secondary,
  }));

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100%"
      margin={2}
    >
      {<LargeIcon component={icon} />}
      <Typography variant="body2" color="textSecondary">{text}</Typography>
    </Box>
  );
}
