import React from "react";
import PageWithSidebar from "../misc/PageWithSidebar";
import OverheadsTable from "./OverheadsTable";
import OverheadsInformationSidebar from "./OverheadsInformationSidebar";

export default function Overheads() {
  return (
    <PageWithSidebar
      page={<OverheadsTable />}
      sidebar={<OverheadsInformationSidebar />}
    />
  );
}
