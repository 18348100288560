import React from "react";

import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";

export default function ModalPage({ isOpened, handleClose, page }) {
  const close = () => {
    handleClose(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "8px",
    boxShadow: 24,
  };

  return (
    <Modal
      open={isOpened}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>{page}</Box>
    </Modal>
  );
}
