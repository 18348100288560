import React from "react";
import { Box, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { Link } from "react-router-dom";
import { useTheme } from "@emotion/react";

export default function CollapseMenuItem({ name, link, icon, toggleDrawer }) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        padding: 1,
        color: theme.palette.text.primary,
      }}
    >
      <ListItemButton
        component={Link}
        to={link}
        sx={{ pl: 2 }}
        onClick={toggleDrawer(false)}
      >
        <ListItemIcon sx={{ color: theme.palette.text.primary }} >
          {icon}
        </ListItemIcon>
        <ListItemText primary={name} sx={{ color: theme.palette.text.primary }} />
      </ListItemButton>
    </Box>
  );
}
