import * as React from "react";
import DropdownNavbarButton from './DropdownNavbarButton';
import HouseSidingIcon from '@mui/icons-material/HouseSiding';
import AssignmentIcon from '@mui/icons-material/Assignment';

export default function TasksDropdownNavbarButton() {
    const menuItems = [
        {
            name: "My Tasks",
            link: "/tasks/user",
            icon: <AssignmentIcon sx={{ marginRight: 1 }} />,
        },
        {
            name: "All Tasks",
            link: "/tasks",
            icon: <HouseSidingIcon sx={{ marginRight: 1 }} />,
        },
    ];

    return (
        <DropdownNavbarButton
            name="Tasks"
            icon={<HouseSidingIcon sx={{ marginRight: 1 }} />}
            menuItems={menuItems}
        />
    );
}
