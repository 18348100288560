import React from "react";

import Grid from "@mui/material/Unstable_Grid2";

export default function PageWithSidebar({ page, sidebar }) {
  return (
    <Grid container>
      <Grid xs={12} md={8.5}>
        {page}
      </Grid>
      <Grid xs={12} md={3.5}>
        {sidebar}
      </Grid>
    </Grid>
  );
}
