import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Table from "../../misc/Table";
import { taskService } from "../../../services/schedule/TaskService";
import { personDataService } from "../../../services/user/PersonsService";
import DropdownSelect from "../../misc/DropdownSelect";
import CustomCheckbox from "../../misc/CustomCheckbox";
import ImageBoolean from "../../misc/ImageBoolean";
import TextField from "@mui/material/TextField";
import { Chip, Typography } from "@mui/material";

export default function TaskTable() {
  const queryClient = useQueryClient();

  const { isLoading, data } = useQuery({
    queryKey: ["tasks.table.data"],
    queryFn: () => taskService.getAll().then((res) => res.data),
  });

  const { data: personData } = useQuery({
    queryKey: ["common.personData"],
    queryFn: () => personDataService.getAllNames().then((res) => res.data),
    placeholderData: [],
  });

  const { data: frequencies } = useQuery({
    queryKey: ["tasks.frequencies"],
    queryFn: () => taskService.getFrequencies().then((res) => res.data),
    placeholderData: [],
  });

  const getWeekNumber = (date) => {
    const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    const startOfYear = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    const dayOfYear = Math.floor((d - startOfYear) / 86400000);
    return Math.ceil((dayOfYear + 1) / 7);
  };

  const weeks = Array.from({ length: 53 }, (_, index) => 1 + index);
  const years = Array.from({ length: 5 }, (_, index) => new Date().getFullYear() + index);

  const [editRowId, setEditRowId] = useState(null);
  const [editRowData, setEditRowData] = useState({});
  const [isCreatingRow, setIsCreatingRow] = useState(false);

  const deleteMutation = useMutation({
    mutationFn: (id) => taskService.delete(id),
    onSuccess: () => queryClient.invalidateQueries(["tasks.table.data"]),
  });

  const upsertMutation = useMutation({
    mutationFn: (newData) => taskService.save(newData),
    onSuccess: () => queryClient.invalidateQueries(["tasks.table.data"]),
  });

  const handleEditClick = (row) => {
    setEditRowId(row.id);
    setEditRowData(row);
  };

  const handleSaveEdit = () => {
    if (editRowId === "new") {
      // Create new entry
      upsertMutation.mutate(editRowData);
    } else {
      // Update existing entry
      upsertMutation.mutate(editRowData);
    }
    setEditRowId(null);
    setIsCreatingRow(false);
  };

  const handleCancelEdit = () => {
    setEditRowId(null);
    setIsCreatingRow(false);
  };

  const handleDelete = (row) => {
    deleteMutation.mutate(row.id);
  };

  const handleCreateNewRowClick = () => {
    setIsCreatingRow(true);
    setEditRowId("new");
    setEditRowData({
      name: "",
      assignee: "",
      frequency: "",
      upcomingWeek: getWeekNumber(new Date()),
      upcomingYear: years[0],
      shared: true,
    });
  };

  const isEditingRow = (id) => editRowId === id;

  const columns = [
    {
      header: "Name",
      accessorKey: "name",
      renderCell: (row) =>
        isEditingRow(row.id) ? (
          <TextField
            fullWidth
            value={editRowData.name || ""}
            onChange={(e) => setEditRowData({ ...editRowData, name: e.target.value })}
          />
        ) : (
          row.name
        ),
    },
    {
      header: "Current Assignee",
      accessorKey: "assignee",
      renderCell: (row) =>
        isEditingRow(row.id) ? (
          <DropdownSelect
            options={personData}
            value={editRowData.assignee}
            onChange={(selectedValue) => setEditRowData({ ...editRowData, assignee: selectedValue })}
          />
        ) : (
          row.assignee
        ),
    },
    {
      header: "Frequency",
      accessorKey: "frequency",
      renderCell: (row) =>
        isEditingRow(row.id) ? (
          <DropdownSelect
            options={frequencies}
            value={editRowData.frequency}
            onChange={(selectedValue) => setEditRowData({ ...editRowData, frequency: selectedValue })}
          />
        ) : (
          row.frequency
        ),
    },
    {
      header: "Upcoming Week",
      accessorKey: "upcomingWeek",
      renderCell: (row) =>
        isEditingRow(row.id) ? (
          <DropdownSelect
            options={weeks}
            value={editRowData.upcomingWeek}
            onChange={(selectedValue) => setEditRowData({ ...editRowData, upcomingWeek: selectedValue })}
          />
        ) : (
          row.upcomingWeek
        ),
    },
    {
      header: "Upcoming Year",
      accessorKey: "upcomingYear",
      renderCell: (row) =>
        isEditingRow(row.id) ? (
          <DropdownSelect
            options={years}
            value={editRowData.upcomingYear}
            onChange={(selectedValue) => setEditRowData({ ...editRowData, upcomingYear: selectedValue })}
          />
        ) : (
          row.upcomingYear
        ),
    },
  ];

  const renderSummary = (row) => (
    <>
      <Chip label={`${row.assignee}`} variant="outlined" />
      <Chip label={`CW ${row.upcomingWeek} of ${row.upcomingYear}`} variant="outlined" />
      <Chip label={`${row.frequency}`} variant="outlined" />
    </>
  );

  const renderStatus = (row) => {
    return (
      <Chip
        color={"info"}
        label={row.shared ? "Shared" : "Not Shared"}
        variant="outlined" />
    );
  };

  return (
    <Table
      name={"Tasks"}
      columns={columns}
      data={data}
      isLoading={isLoading}
      onDelete={handleDelete}
      onEditClick={handleEditClick}
      onSaveEdit={handleSaveEdit}
      onCancelEdit={handleCancelEdit}
      isEditingRow={isEditingRow}
      isCreatingRow={isCreatingRow}
      onCreateNewRowClick={handleCreateNewRowClick}
      editRowData={editRowData}
      setEditRowData={setEditRowData}
      renderSummary={renderSummary}
      renderStatus={renderStatus}
    />
  );
}
