import { Checkbox, TableCell, TableRow, Typography, useTheme } from "@mui/material";
import React from "react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Chip from "@mui/material/Chip";
import { taskService } from "../../../services/schedule/TaskService";

function ScheduleItem({ user, task }) {
    const queryClient = useQueryClient();
    const theme = useTheme();

    const doneMutation = useMutation({
        mutationFn: (taskId) => {
            taskService.complete(
                {
                    id: taskId
                }
            );
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries("tasks.table.data");
            await queryClient.invalidateQueries("schedule.overdue");
            await queryClient.invalidateQueries("schedule", user);
        },
    });

    const text = <>
        <Typography key={task.id}>
            {task.name}
        </Typography>
        {task.overdue && (
            <Chip label={`overdue for ${task.assignee}`} color="warning" variant="outlined" />
        )}
    </>

    return (
        <TableRow
            sx={{
                '&:last-child td, &:last-child th': { borderBottom: 0 }
            }}
        >
            <TableCell align="center">{text}</TableCell>
            <TableCell align="center">
                <Checkbox
                    onChange={() => doneMutation.mutateAsync(task.id)}
                    icon={<CheckCircleIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    sx={{
                        transform: "scale(1.5)",
                        color: theme.palette.primary.main,
                        '&.Mui-checked': {
                            color: theme.palette.primary.main,
                        },
                    }}
                />
            </TableCell>
        </TableRow>
    );
}

export default ScheduleItem;
