import { useEffect } from 'react';
import instance from '../../services/Axios';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';

const AxiosInterceptorSetup = () => {
  const { user, isAuthenticated, removeUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const requestInterceptor = instance.interceptors.request.use(
      (config) => {
        if (isAuthenticated) {
          config.headers.Authorization = `Bearer ${user.token}`;
        }
        else {
          navigate('/login', { replace: true });
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    const responseInterceptor = instance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          removeUser();
          navigate('/login', { replace: true });
        }
        return Promise.reject(error);
      }
    );

    return () => {
      instance.interceptors.request.eject(requestInterceptor);
      instance.interceptors.response.eject(responseInterceptor);
    };
  }, [user, isAuthenticated, removeUser, navigate]);

  return null;
};

export default AxiosInterceptorSetup;
