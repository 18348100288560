import instance from "../Axios";

class ElectricityService {
  save(data) {
    return instance.post(`/castle/electricity/`, data);
  }

  getAll() {
    return instance.get(`/castle/electricity/`);
  }

  getLatest() {
    return instance.get(`/castle/electricity/latest`);
  }

  delete(data) {
    return instance.delete(`/castle/electricity/`, { data: data });
  }
}

export let electricityService = new ElectricityService();
