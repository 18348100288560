import React, { useState } from "react";
import {
  Box,
  Button,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { boxHeader, boxHeaderTitle } from "../../Styles";
import { castleService } from "../../../services/castle/CastleService";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { personDataService } from "../../../services/user/PersonsService";
import DropdownSelect from "../../misc/DropdownSelect";
import Loading from "../../misc/Loading";

export default function SettingsPage({ currentUser }) {
  const queryClient = useQueryClient();
  const [selectedCastle, setSelectedCastle] = useState("");

  const { isLoading: isLoadingCastles, data: allCastles } = useQuery({
    queryKey: ["castles.all"],
    queryFn: () => castleService.getAll().then((res) => res.data),
    placeholderData: [],
  });

  const { isLoading: isLoadingCurrentCastle, data: currentCastle } = useQuery({
    queryKey: ["person.castle.current", currentUser],
    queryFn: () =>
      personDataService.getCurrentCastle(currentUser).then((res) => res.data),
    placeholderData: "",
    onSuccess: () => {
      setSelectedCastle(currentCastle)
    },
  });

  const settingsMutation = useMutation({
    mutationFn: (input) => {
      personDataService.save(input);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("castles.all");
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      settingsMutation.mutateAsync({
        name: currentUser,
        castleName: selectedCastle || currentCastle,
      });
    } catch (error) { }
  };

  if (isLoadingCastles || isLoadingCurrentCastle) {
    return (
      <Loading />
    );
  }

  return (
    <Box container
      sx={{
        boxShadow: 2,
        borderRadius: "8px",
        margin: "10px",
      }}
    >
      <Paper sx={boxHeader}>
        <Typography variant="h5" sx={boxHeaderTitle}>
          Settings
        </Typography>
      </Paper>
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{ mt: 1 }}
        fullWidth
      >
        <Stack spacing={2} direction="row" sx={{ marginBottom: 4, p: 1 }}>
          <DropdownSelect
            label="Current Castle"
            options={allCastles.map((it) => it.name)}
            value={selectedCastle || currentCastle}
            onChange={(value) => {
              setSelectedCastle(value)
            }}
          />
        </Stack>
        <Button
          fullWidth
          variant="contained"
          sx={{ mt: 3 }}
          type="submit"
          disabled={settingsMutation.isLoading}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
}
