import React from "react";
import { useQuery } from "@tanstack/react-query";
import { splitService } from "../../services/splitsies/SplitService";
import OwedList from "./OwedList";
import Loading from "../misc/Loading";

export default function SplitInformationSidebar() {
  const { isLoading, data } = useQuery({
    queryKey: ["splitsies.information"], queryFn: () => splitService.split().then((res) => res.data.owned),
  });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <OwedList title={"Tasks"} isLoading={isLoading} data={data} />
  );
}
