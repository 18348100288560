import React, { useState } from "react";
import {
  Card,
  Grid,
  Typography,
  Box,
  CircularProgress,
  IconButton,
  TextField,
  useMediaQuery,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Save as SaveIcon,
  Cancel as CancelIcon,
  Add,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

export default function Table({
  name,
  columns,
  data,
  isLoading,
  onDelete,
  onEditClick,
  onSaveEdit,
  onCancelEdit,
  isEditingRow,
  onCreateNewRowClick,
  isCreatingRow,
  editRowData,
  setEditRowData,
  renderSummary,
  renderStatus
}) {
  const theme = useTheme();
  const displayedData = isCreatingRow ? [{ id: "new", ...{} }, ...data] : data;
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // Local state to track which rows are expanded
  const [expandedRows, setExpandedRows] = useState({});
  const [isNewRowActive, setIsNewRowActive] = useState(isCreatingRow);

  // Toggle expand/collapse for a specific row
  const toggleExpandRow = (rowId) => {
    setExpandedRows((prev) => ({ ...prev, [rowId]: !prev[rowId] }));
  };

  // Handle Edit click for existing rows
  const handleEditClick = (row) => {
    if (isNewRowActive) {
      // If a new row is being created, cancel it before allowing editing of other rows
      handleCancelNewRow();
    }
    onEditClick(row); // Proceed with editing the selected row
  };

  // Handle canceling the creation of a new row
  const handleCancelNewRow = () => {
    setIsNewRowActive(false); // Hide the new row form
    onCancelEdit(); // Reset the edit state
  };

  return (
    <Box sx={{ margin: "16px", padding: "16px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "0px",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#009688",
            color: "#fff",
            padding: "8px 32px",
            borderRadius: "8px 8px 0 0",
            display: "inline-block",
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {name}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "8px",
          }}
        >
          <IconButton
            color="primary"
            sx={{
              backgroundColor: theme.palette.primary.main,
              color: "#fff",
              borderRadius: "8px",
              padding: "8px 16px",
              fontSize: "16px",
              textTransform: "none",
              "&:hover": {
                backgroundColor: theme.palette.primary.dark,
              },
            }}
            onClick={() => {
              setIsNewRowActive(true); // Activate new row creation
              onCreateNewRowClick(); // Handle the new row creation process
            }}
          >
            <Add sx={{ fontSize: 28 }} />
          </IconButton>
        </Box>
      </Box>

      <Box
        sx={{
          borderBottom: "1px solid #e0e0e0",
          marginBottom: "16px",
        }}
      />

      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress />
        </Box>
      ) : displayedData.length === 0 ? (
        <Typography align="center" sx={{ fontSize: "18px", color: "#757575" }}>
          No data available
        </Typography>
      ) : (
        <>
          {displayedData.map((row, rowIndex) => {
            const isExpanded = expandedRows[row.id] || row.id === "new"; // Always expand new row
            const isNewRow = row.id === "new"; // Check if it's a new row
            const isEditing = isEditingRow(row.id); // Check if the row is in editing mode

            return (
              <Card
                key={rowIndex}
                sx={{
                  marginBottom: "16px",
                  padding: "24px",
                  borderRadius: "12px",
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                  backgroundColor: "#FAFAFA",
                  position: "relative",
                }}
              >
                {/* Top Row: Name, Summary Info, Expand/Collapse Button */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "16px",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "bold", fontSize: "1.25rem", color: "#424242" }}
                  >
                    {isEditing ? (
                      <TextField
                        fullWidth
                        placeholder="New Entry Name"
                        value={editRowData.name || ""}
                        onChange={(e) =>
                          setEditRowData({ ...editRowData, name: e.target.value })
                        }
                        sx={{
                          fontSize: "1.25rem",
                          borderRadius: "4px",
                        }}
                      />
                    ) : (
                      row.name || "Unnamed Entry"
                    )}
                  </Typography>

                  {/* Right-aligned Summary and Expand/Collapse Button */}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      flexGrow: 1,
                      gap: 2,
                    }}
                  >
                    {!isNewRow && renderStatus && !isSmallScreen && (
                      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                        {renderStatus(row)}
                      </Box>
                    )}

                    {/* Action Buttons for Editing a new row */}
                    {isExpanded && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end", // Align to the right
                          alignItems: "center",
                          gap: 2,
                        }}
                      >
                        {isEditing ? (
                          <>
                            <IconButton
                              color="primary"
                              sx={{ padding: "12px", fontSize: 28 }}
                              onClick={onSaveEdit}
                            >
                              <SaveIcon sx={{ fontSize: 28 }} />
                            </IconButton>
                            <IconButton
                              color="error"
                              sx={{ padding: "12px", fontSize: 28 }}
                              onClick={onCancelEdit}
                            >
                              <CancelIcon sx={{ fontSize: 28 }} />
                            </IconButton>
                          </>
                        ) : (
                          <>
                            <IconButton
                              color="primary"
                              sx={{ padding: "12px", fontSize: 28 }}
                              onClick={() => handleEditClick(row)}
                            >
                              <EditIcon sx={{ fontSize: 28 }} />
                            </IconButton>
                            <IconButton
                              color="error"
                              sx={{ padding: "12px", fontSize: 28 }}
                              onClick={() => onDelete(row)}
                            >
                              <DeleteIcon sx={{ fontSize: 28 }} />
                            </IconButton>
                          </>
                        )}
                      </Box>
                    )}

                    {/* Summarized Information (Visible when collapsed, but not for new rows) */}
                    {!isExpanded && !isNewRow && renderSummary && !isSmallScreen && (
                      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                        {renderSummary(row)}
                      </Box>
                    )}

                    {/* Expand/Collapse Button (Visible even when collapsed) */}
                    {!isNewRow && (
                      <IconButton
                        onClick={() => toggleExpandRow(row.id)}
                        sx={{
                          zIndex: 10,
                        }}
                        disabled={isEditing} // Disable the button in edit mode
                      >
                        {isExpanded ? (
                          <ExpandLessIcon sx={{ fontSize: 28 }} />
                        ) : (
                          <ExpandMoreIcon sx={{ fontSize: 28 }} />
                        )}
                      </IconButton>
                    )}
                  </Box>
                </Box>

                {!isExpanded && isSmallScreen && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                      gap: 1,
                      marginBottom: "16px",
                    }}
                  >
                    {/* Status Chip */}
                    {!isNewRow && renderStatus && (
                      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                        {renderStatus(row)}
                      </Box>
                    )}

                    {/* Summary Chips */}
                    {!isNewRow && renderSummary && (
                      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                        {renderSummary(row)}
                      </Box>
                    )}
                  </Box>
                )}

                {/* Detailed Information */}
                {isExpanded && (
                  <Grid container spacing={2}>
                    {columns.map((column, colIndex) =>
                      column.accessorKey !== "name" ? (
                        <Grid item xs={12} sm={6} md={4} key={colIndex}>
                          <Typography
                            variant="subtitle2"
                            color="textSecondary"
                            sx={{ marginBottom: "4px" }}
                          >
                            {column.header}
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{
                              fontSize: "16px",
                              fontWeight: "500",
                              color: "#424242",
                            }}
                          >
                            {column.renderCell
                              ? column.renderCell(row)
                              : row[column.accessorKey]}
                          </Typography>
                        </Grid>
                      ) : null
                    )}
                  </Grid>
                )}
              </Card>
            );
          })}
        </>
      )}
    </Box>
  );
}
