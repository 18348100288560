import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import ListItem from "../misc/ListItem";

export default function OwedOverhead({ item }) {

  return (
    <ListItem
      key={item.month}
      content={
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }} align="center">Pays</TableCell>
                <TableCell sx={{ fontWeight: "bold" }} align="center">Gets Paid</TableCell>
                <TableCell sx={{ fontWeight: "bold" }} align="center">Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{
                  '&:last-child td, &:last-child th': { borderBottom: 0 },
                  textDecoration: "none",
                  opacity: 1,
                }}
              >
                <TableCell align="center">{item.payer}</TableCell>
                <TableCell align="center">{item.payee}</TableCell>
                <TableCell align="center">€{item.amount.toLocaleString()}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      }
    />
  );
}
