import React, { useState } from "react";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { itemService } from "../../services/splitsies/ItemsService";
import { personDataService } from "../../services/user/PersonsService";
import Table from "../misc/Table";
import DropdownSelect from "../misc/DropdownSelect";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MultiSelect from "./MultiSelect";

export default function SplitsiesTable() {
  const queryClient = useQueryClient();

  const { isLoading, data } = useQuery({
    queryKey: ["splitsies.table.data"],
    queryFn: () => itemService.getActive().then((res) => res.data),
  });

  const { data: personData } = useQuery({
    queryKey: ["common.personData"],
    queryFn: () => personDataService.getAllNames().then((res) => res.data),
    placeholderData: [],
  });

  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  const [editRowId, setEditRowId] = useState(null);
  const [editRowData, setEditRowData] = useState({});
  const [isCreatingRow, setIsCreatingRow] = useState(false);

  const deleteMutation = useMutation({
    mutationFn: (id) => itemService.delete(id),
    onSuccess: () => queryClient.invalidateQueries(["splitsies.table.data"]),
  });

  const upsertMutation = useMutation({
    mutationFn: (newData) => itemService.save(newData),
    onSuccess: () => queryClient.invalidateQueries(["splitsies.table.data"]),
  });

  const handleEditClick = (row) => {
    setEditRowId(row.id);
    setEditRowData(row);
  };

  const handleSaveEdit = () => {
    if (editRowId === "new") {
      // Create new entry
      upsertMutation.mutate(editRowData);
    } else {
      // Update existing entry
      upsertMutation.mutate(editRowData);
    }
    setEditRowId(null);
    setIsCreatingRow(false);
  };

  const handleCancelEdit = () => {
    setEditRowId(null);
    setIsCreatingRow(false);
  };

  const handleDelete = (row) => {
    deleteMutation.mutate(row.id);
  };

  const handleCreateNewRowClick = () => {
    setIsCreatingRow(true);
    setEditRowId("new");
    setEditRowData({
      name: "",
      description: "",
      price: "",
      payingPerson: "",
      splittingPersons: [],
      month: monthNames[new Date().getMonth()],
      settled: false,
    });
  };

  const isEditingRow = (id) => editRowId === id;

  const columns = [
    {
      header: "Name",
      accessorKey: "name",
      renderCell: (row) =>
        isEditingRow(row.id) ? (
          <TextField
            fullWidth
            value={editRowData.name || ""}
            onChange={(e) => setEditRowData({ ...editRowData, name: e.target.value })}
          />
        ) : (
          row.name
        ),
    },
    {
      header: "Description",
      accessorKey: "description",
      renderCell: (row) =>
        isEditingRow(row.id) ? (
          <TextField
            fullWidth
            value={editRowData.description || ""}
            onChange={(e) => setEditRowData({ ...editRowData, description: e.target.value })}
          />
        ) : (
          row.description
        ),
    },
    {
      header: "Price",
      accessorKey: "price",
      renderCell: (row) =>
        isEditingRow(row.id) ? (
          <TextField
            fullWidth
            type="number"
            value={editRowData.price || ""}
            onChange={(e) => setEditRowData({ ...editRowData, price: e.target.value })}
          />
        ) : (
          `€${row.price.toFixed(2)}`
        ),
    },
    {
      header: "Paid By",
      accessorKey: "payingPerson",
      renderCell: (row) =>
        isEditingRow(row.id) ? (
          <DropdownSelect
            options={personData}
            value={editRowData.payingPerson}
            onChange={(selectedValue) => setEditRowData({ ...editRowData, payingPerson: selectedValue })}
          />
        ) : (
          row.payingPerson
        ),
    },
    {
      header: "Split By",
      accessorKey: "splittingPersons",
      renderCell: (row) =>
        isEditingRow(row.id) ? (
          <MultiSelect
            data={personData}
            value={editRowData.splittingPersons || []}
            onChange={(selectedValues) => setEditRowData({ ...editRowData, splittingPersons: selectedValues })}
          />
        ) : (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {row.splittingPersons.map((it) => (
              <Chip key={it} label={it} />
            ))}
          </Box>
        ),
    },
    {
      header: "Month",
      accessorKey: "month",
      renderCell: (row) =>
        isEditingRow(row.id) ? (
          <DropdownSelect
            options={monthNames}
            value={editRowData.month}
            onChange={(selectedValue) => setEditRowData({ ...editRowData, month: selectedValue })}
          />
        ) : (
          row.month
        ),
    }
  ];

  const renderSummary = (row) => (
    <>
      <Chip label={`€${row.price}`} variant="outlined" />
      <Chip label={`${row.payingPerson}`} variant="outlined" />
      <Chip label={`${row.month}`} variant="outlined" />
    </>
  );

  const renderStatus = (row) => {
    return (
      <Chip color={row.settled ? "success" : "info"}
        label={row.settled ? "Settled" : "Not Settled"}
        variant="outlined" />
    );
  };

  return (
    <Table
      name={"Splitsies"}
      columns={columns}
      data={data}
      isLoading={isLoading}
      onDelete={handleDelete}
      onEditClick={handleEditClick}
      onSaveEdit={handleSaveEdit}
      onCancelEdit={handleCancelEdit}
      isEditingRow={isEditingRow}
      isCreatingRow={isCreatingRow}
      onCreateNewRowClick={handleCreateNewRowClick}
      editRowData={editRowData}
      setEditRowData={setEditRowData}
      renderSummary={renderSummary} // Pass summarized info here
      renderStatus={renderStatus}
    />
  );
}
