import React from "react";
import { Box, Typography, Paper, List } from "@mui/material";
import { boxHeader, boxHeaderTitle } from "../Styles";
import { styled } from '@mui/material/styles';
import _ from "lodash";
import Loading from "./Loading";

export default function ItemWithHeader({ title, isLoading, emptyElement, elementsNotPresent, elements }) {

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    if (isLoading) {
        return <Loading />
    }

    return (
        <Item container
            sx={{
                boxShadow: 2,
                borderRadius: "8px",
                margin: "10px",
            }}
        >
            <Paper sx={boxHeader}>
                <Typography variant="h5" sx={boxHeaderTitle}>
                    {title}
                </Typography>
            </Paper>
            {elementsNotPresent ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {emptyElement}
                </Box>
            ) : (
                <List sx={{ width: '100%', paddingBottom: 1 }}>
                    {elements}
                </List>
            )}
        </Item>
    );
}
