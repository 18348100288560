import React from "react";
import SplitsiesTable from "./SplitsiesTable";
import SplitInformationSidebar from "./SplitInformationSidebar";
import PageWithSidebar from "../misc/PageWithSidebar";

export default function Splitsies() {
  return (
    <PageWithSidebar
      page={<SplitsiesTable />}
      sidebar={<SplitInformationSidebar />}
    />
  );
}
