import React, { useEffect, useState } from "react";
import QuickAddForm from "./QuickAddForm";
import {
  Box,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import { boxHeader, boxHeaderTitle } from "../../Styles";
import { useQuery } from "@tanstack/react-query";
import { personDataService } from "../../../services/user/PersonsService";
import { useAuth } from "../../context/AuthContext";

export default function QuickAdd() {
  const { user } = useAuth();
  const [readingType, setReadingType] = useState("");
  const [showWaterSegment, setShowWaterSegment] = useState(false);
  const [showElectricitySegment, setShowElectricitySegment] = useState(false);

  const { data: currentCastle } = useQuery({
    queryKey: ["persons.castles.current"],
    queryFn: () =>
      personDataService.getCurrentCastle(user.name).then((res) => res.data),
    placeholderData: "",
  });

  useEffect(() => {
    const handleChange = async () => {
      if (readingType === "electricity") {
        setShowElectricitySegment(true);
        setShowWaterSegment(false);
      }

      if (readingType === "water") {
        setShowElectricitySegment(false);
        setShowWaterSegment(true);
      }
    };

    handleChange();
  }, [readingType, currentCastle]);

  const handleChange = (event) => {
    setReadingType(event.target.value);
  };

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Box
        sx={{
          boxShadow: 2,
          borderRadius: "8px",
          width: "450px",
        }}
      >
        <Paper sx={boxHeader}>
          <Typography variant="h5" sx={boxHeaderTitle}>
            Add Reading
          </Typography>
        </Paper>
        <Box noValidate sx={{ mt: 1, padding: "10px" }}>
          <InputLabel id="readingType">Reading Type</InputLabel>
          <Select
            variant="outlined"
            labelId="readingType"
            id="readingType"
            value={readingType}
            label="Reading Type"
            onChange={handleChange}
            fullWidth
          >
            <MenuItem value={"electricity"}>Electricity</MenuItem>
            <MenuItem value={"water"}>Water</MenuItem>
          </Select>

          <QuickAddForm
            showElectricitySegment={showElectricitySegment}
            showWaterSegment={showWaterSegment}
            currentCastle={currentCastle}
          />
        </Box>
      </Box>
    </Grid>
  );
}
