import instance from "../Axios";

class CastleService {

  save(data) {
    return instance.post(`/castle/`, data);
  }

  getAll() {
    return instance.get(`/castle/`);
  }

  triggerAnalysis() {
    return instance.get(`/castle/analysis/refresh`);
  }

  reset() {
    return instance.get(`/castle/analysis/reset`);
  }

  getWaterForCastle(name) {
    return instance.get(`/castle/analysis/${name}/water`);
  }

  getElectricityForCastle(name) {
    return instance.get(`/castle/analysis/${name}/electricity`);
  }

  getWater() {
    return instance.get(`/castle/analysis/water`);
  }

  getElectricity() {
    return instance.get(`/castle/analysis/electricity`);
  }
}

export let castleService = new CastleService();
