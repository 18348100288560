import React from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Divider,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import CollapseMenuItem from "./CollapseMenuItem";

export default function CollapseDrawerMenu({ icon, text, menuItems, toggleDrawer }) {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const theme = useTheme();

  const listElements = menuItems.map((item, index) => (
    <React.Fragment key={index}>
      <CollapseMenuItem
        name={item.name}
        link={item.link}
        icon={item.icon}
        toggleDrawer={toggleDrawer}
      />
      {index < menuItems.length - 1 && (
        <Divider variant="middle" component="li" />
      )}
    </React.Fragment>
  ));

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          padding: 1,
          color: theme.palette.text.primary,
        }}
      >
        <ListItemButton onClick={handleClick}>
          <ListItemIcon sx={{ color: theme.palette.text.primary }} >{icon}</ListItemIcon>
          <ListItemText primary={text} sx={{ color: theme.palette.text.primary }} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </Box>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List disablePadding>
          {listElements}
        </List>
      </Collapse>
    </>
  );
}
