// Header Styles
export const boxHeader = {
    backgroundColor: "#00897B",
    padding: "8px",
    textAlign: "center",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    borderBottomLeftRadius: "0px",
    borderBottomRightRadius: "0px",
};

// Header Title Styles
export const boxHeaderTitle = {
    color: "white",
    fontFamily: "Georgia, serif",
    fontSize: "1.5rem",
    mb: '3px'
};

export const container = {
    textAlign: "center",
    marginTop:
        100
}
