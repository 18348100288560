import React from "react";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import CollapseDrawerMenu from "./CollapseDrawerMenu";

export default function SplitsiesCollapseDrawerMenu({ toggleDrawer }) {
  const menuItems = [
    { name: "Splitsies", link: "/splitsies", icon: <MonetizationOnIcon /> },
    { name: "Overheads", link: "/overheads", icon: <CurrencyExchangeIcon /> }
  ];

  return (
    <CollapseDrawerMenu
      icon={<MonetizationOnIcon />}
      text="Splitsies"
      menuItems={menuItems}
      toggleDrawer={toggleDrawer}
    />
  );
}
