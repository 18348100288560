import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { handleLogError } from "../misc/Helper";
import { Alert, Box, Button, Grid, Paper, Stack, TextField, Typography } from "@mui/material";
import { boxHeader, boxHeaderTitle } from "../Styles";

function Login() {
  const { isAuthenticated, loginMutation } = useAuth();
  let navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!(username && password)) {
      return;
    }

    try {
      await loginMutation.mutateAsync({ name: username, password: password });

      navigate(`/`);
    } catch (error) {
      handleLogError(error);
    }
  };

  if (isAuthenticated) {
    navigate(`/`);
  }

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Box
        sx={{
          boxShadow: 2,
          borderRadius: "8px",
          width: "450px",
        }}
      >
        <Paper sx={boxHeader}>
          <Typography variant="h5" sx={boxHeaderTitle}>
            Login
          </Typography>
        </Paper>

        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, padding: "10px"}}>
          <Stack spacing={2} sx={{ marginBottom: 4}}>
            <TextField
              type="text"
              icon="user"
              variant="outlined"
              label="Username"
              onChange={(e) => setUsername(e.target.value)}
              value={username}
              fullWidth
              required
            />
            <TextField
              type="password"
              icon="lock"
              variant="outlined"
              label="Password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              fullWidth
              required
            />
          </Stack>
          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 2, mb: 2 }}
            type="submit"
          >
            Login
          </Button>
          {loginMutation.isError ? (
            <div>
              <Alert severity="error">
                The username or password are wrong.
              </Alert>
           </div>
          ) : null}
        </Box>
      </Box>
    </Grid>
  );
}

export default Login;
