import React from "react";
import { useQuery } from "@tanstack/react-query";
import { taskService } from "../../../services/schedule/TaskService";
import Loading from "../../misc/Loading";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import ListItemWithTab from "../../misc/ListItemWithTab";

export default function TaskInfo() {
    const { isLoading, data } = useQuery({
        queryKey: ["tasks.load"],
        queryFn: () => taskService.getLoad().then((res) => res.data),
    });

    if (isLoading) {
        return <Loading />;
    }

    return (
        data.map((item) => (
            <ListItemWithTab
                key={item.frequency}
                category={item.frequency}
                content={
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ fontWeight: "bold" }} align="center">Person</TableCell>
                                    <TableCell sx={{ fontWeight: "bold" }} align="right">Task Count</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {formatCountsData(item.userTaskCounts).map((row, index) => (
                                    <TableRow key={index}
                                        sx={{
                                            '&:last-child td, &:last-child th': { borderBottom: 0 }
                                        }}
                                    >
                                        <TableCell align="center">{row.person}</TableCell>
                                        <TableCell align="right">{row.tasks}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
            />
        ))
    );
}

function formatCountsData(userCounts) {
    return Object.entries(userCounts).map(([person, tasks]) => ({
        person,
        tasks,
    }));
}
