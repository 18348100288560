import * as React from "react";
import AddIcon from '@mui/icons-material/Add';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import TungstenIcon from '@mui/icons-material/Tungsten';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import CastleIcon from '@mui/icons-material/Castle';
import DropdownNavbarButton from './DropdownNavbarButton';


export default function CastleDropdownNavbarButton() {
    const menuItems = [
        {
            name: "Quick Add",
            link: "/castle/quick",
            icon: <AddIcon sx={{ marginRight: 1 }} />,
        },
        {
            name: "Water",
            link: "/castle/water",
            icon: <WaterDropIcon sx={{ marginRight: 1 }} />,
        },
        {
            name: "Electricity",
            link: "/castle/electricity",
            icon: <TungstenIcon sx={{ marginRight: 1 }} />,
        },
        {
            name: "Analysis",
            link: "/castle/analysis",
            icon: <TextSnippetIcon sx={{ marginRight: 1 }} />,
        },
    ];

    return (
        <DropdownNavbarButton
            name="Castle"
            icon={<CastleIcon sx={{ marginRight: 1 }} />}
            menuItems={menuItems}
        />
    );
}
