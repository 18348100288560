import React from 'react';
import { Box } from '@mui/material';
import DesktopNavbarButton from "./DesktopNavbarButton";
import DashboardIcon from '@mui/icons-material/Dashboard';
import CastleDropdownNavbarButton from "./CastleDropdownNavbarButton";
import TasksDropdownNavbarButton from './TasksDropdownNavbarButton';
import SplitsiesDropdownNavbarButton from './SplitsiesDropdownNavbarButton';

export default function DesktopMenuItems({ isAuthenticated }) {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 2
            }}
        >
            {isAuthenticated && (
                <>
                    <DesktopNavbarButton name={"Dashboard"} link={"/dashboard"} icon={<DashboardIcon sx={{ marginRight: 1 }} />}/>
                    <SplitsiesDropdownNavbarButton />
                    <TasksDropdownNavbarButton />
                    <CastleDropdownNavbarButton />
                </>
            )}
        </Box>
    );
}