import instance from "../Axios";

class SplitService {

    split() {
        return instance.post(`/splitsies/split/`, {});
    }

    settle(data) {
        return instance.post(`/splitsies/split/settle`, data);
    }
}

export let splitService = new SplitService();