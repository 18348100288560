import instance from "../Axios";

class TaskService {

    save(data) {
        return instance.post(`/tasks/`, data);
    }

    getAll() {
        return instance.get(`/tasks/`);
    }

    getById(id) {
        return instance.get(`/tasks/${id}`);
    }

    getFrequencies() {
        return instance.get(`/tasks/frequencies`);
    }

    delete(data) {
        return instance.delete(`/tasks/${data}`);
    }

    complete(data) {
        return instance.post(`/tasks/complete-task`, data);
    }

    getLoad() {
        return instance.get(`/tasks/load`);
    }

    getOverdues() {
        return instance.get(`/tasks/overdue`);
    }

    getTasksDueThisWeekForPerson(personName) {
        return instance.get(`/tasks/week/${personName}`);
    }
}

export let taskService = new TaskService();