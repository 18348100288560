import React from "react";
import { Link } from "react-router-dom";
import { Box, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useTheme } from "@emotion/react";

export default function MobileNavbarButton({ name, link, toggleDrawer, icon }) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        padding: 1,
        color: theme.palette.text.primary,
      }}
    >
      <ListItemButton component={Link} to={link} onClick={toggleDrawer(false)}>
        <ListItemIcon sx={{ color: "inherit" }}>{icon}</ListItemIcon>
        <ListItemText primary={name} />
      </ListItemButton>
    </Box>
  );
}
