import React from 'react';
import TextField from '@mui/material/TextField';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {parseISO} from 'date-fns'

export default function CustomDatePicker({label, helperText, error, value, onChange}) {
    function getValue(value) {
        if (typeof value === "string") {
            return parseISO(value);
        }
        return value;
    }

    function onChangeWithTimeZoneConversion(date) {
        return onChange(new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000));
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                label={label}
                helperText={helperText}
                error={error}
                format="dd/MM/yyyy"
                value={getValue(value)}
                onChange={onChangeWithTimeZoneConversion}
                renderInput={(params) => <TextField {...params} />}
            />
        </LocalizationProvider>
    );
}
