import React from "react";
import QuickAddElectricity from "./QuickAddElectricity";
import QuickAddWater from "./QuickAddWater";

export default function QuickAddForm({
  showWaterSegment,
  showElectricitySegment,
  currentCastle,
}) {
  if (showWaterSegment) {
    return (
      <QuickAddWater readingDate={new Date()} currentCastle={currentCastle} />
    );
  }

  if (showElectricitySegment) {
    return (
      <QuickAddElectricity
        readingDate={new Date()}
        currentCastle={currentCastle}
      />
    );
  }
}
