import React from "react";

import PageWithSidebar from "../../misc/PageWithSidebar";
import DashboardSidebar from "./SettingsSidebar";
import { useAuth } from "../../context/AuthContext";
import Loading from '../../misc/Loading';
import _ from "lodash";
import SettingsPage from "./SettingsPage";

export default function Settings() {
  const { user } = useAuth();

  if (_.isEmpty(user)) {
    return <Loading />
  }

  return (
    <PageWithSidebar
      page={<SettingsPage currentUser={user.name} />}
      sidebar={<DashboardSidebar />}
    />
  );
}
