import React from "react";
import MenuItem from "@mui/material/MenuItem";
import { TextField } from "@mui/material";

export default function DropdownSelect({
  options = [],
  value,
  originalError,
  currentHelperText,
  onChange,
}) {
  return (
    <TextField
      select
      variant="outlined"
      fullWidth
      helperText={currentHelperText}
      error={originalError}
      value={value ?? ""}
      onChange={(event) => {
        onChange(event.target.value);
      }}
    >
      {options.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </TextField>
  );
}
