import { LineChart } from "@mui/x-charts";
import { Box, Paper, Typography } from "@mui/material";
import { boxHeader, boxHeaderTitle } from "../Styles";

export default function Chart({ name, dates, series }) {
  return (
    <Box
      sx={{
        boxShadow: 2,
        borderRadius: "8px",
        margin: "10px",
      }}
    >
      <Paper sx={boxHeader}>
        <Typography variant="h5" sx={boxHeaderTitle}>
          {name}
        </Typography>
      </Paper>
      <LineChart
        xAxis={[{ scaleType: "point", data: dates }]}
        series={series}
        height={400}
      />
    </Box>
  );
}
