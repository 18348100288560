import React, {useState} from "react";
import {
    Box,
    Button,
    Grid,
    Paper,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import {boxHeader, boxHeaderTitle} from "../../Styles";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {castleService} from "../../../services/castle/CastleService";
import CustomDatePicker from "../../misc/CustomDatePicker";

export default function AddCastle({onSubmit}) {
    const queryClient = useQueryClient();
    const [name, setName] = useState("");
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();


    const addCastleMutationMutation = useMutation({
        mutationFn: (input) => {
            castleService.save(input);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries("castles.all");
        },
    });

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            addCastleMutationMutation.mutateAsync({
                name: name,
                startDate: startDate,
                endDate: endDate
            });

            onSubmit();
        } catch (error) {
        }
    };

    return (
        <Grid container justifyContent="center" alignItems="center">
            <Box
                sx={{
                    boxShadow: 2,
                    borderRadius: "8px",
                    width: "450px",
                }}
            >
                <Paper sx={boxHeader}>
                    <Typography variant="h5" sx={boxHeaderTitle}>
                        Create Castle
                    </Typography>
                </Paper>
                <Box component="form" onSubmit={handleSubmit} noValidate>
                    <Stack spacing={2} direction="column" sx={{margin: 4}}>
                        <TextField
                            type="text"
                            variant="outlined"
                            label="Name"
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                            fullWidth
                            required
                        />
                        <CustomDatePicker
                            label={"Start Date"}
                            value={startDate}
                            onChange={(e) => setStartDate(e)}
                        />
                        <CustomDatePicker
                            label={"End Date"}
                            value={endDate}
                            onChange={(e) => setEndDate(e)}
                        />
                    </Stack>
                    <Button
                        fullWidth
                        variant="contained"
                        type="submit"
                        sx={{p: 1, borderRadius: 0}}
                    >
                        Create
                    </Button>
                    {addCastleMutationMutation.isError ? (
                        <div>An error occurred, please try again: {addCastleMutationMutation.error.message}</div>
                    ) : null}

                    {addCastleMutationMutation.isSuccess ? <div>Castle created!</div> : null}
                </Box>
            </Box>
        </Grid>
    );
}
