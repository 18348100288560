import React from "react";
import AllDone from './AllDone';
import Loading from "../misc/Loading";
import ItemWithHeader from "../misc/ItemWithHeader";
import _ from "lodash";
import OverheadItem from "./OverheadItem";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

export default function OverheadCancelledNotification({ title, isLoading, data }) {

    if (isLoading) {
        return <Loading />
    }

    const elements = data?.map((item) => (
        <OverheadItem item={item} />
    ))

    return (
        <ItemWithHeader
            title={title}
            emptyElement={<AllDone text={"All overhead changes acknowledged."} />}
            elementsNotPresent={_.isEmpty(data)}
            elements={
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontWeight: "bold" }} align="center">Name</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }} align="center">Payer</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }} align="center">Amount</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }} align="center">Frequency</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }} align="center">Acknowledge</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {elements}
                        </TableBody>
                    </Table >
                </TableContainer >
            }
        />
    );
}
