import instance from "../Axios";

class OverheadsService {

    save(data) {
        return instance.post(`/splitsies/overheads/`, data);
    }

    getAll() {
        return instance.get(`/splitsies/overheads/`);
    }

    getCancelled() {
        return instance.get(`/splitsies/overheads/cancelled`);
    }

    getMonthlyPayments() {
        return instance.get(`/splitsies/overheads/calculate`);
    }

    getFrequencies() {
        return instance.get(`/splitsies/overheads/frequencies`);
    }
        
    getById(id) {
        return instance.get(`/splitsies/overheads/${id}`);
    }
    
    delete(id) {
        return instance.delete(`/splitsies/overheads/${id}`);
    }
}

export let overheadsService = new OverheadsService();