import { useAuth } from "../../context/AuthContext";
import Loading from "../../misc/Loading";
import ElectricityAnalysisChart from "./ElectricityAnalysisChart";
import WaterAnalysisChart from "./WaterAnalysisChart";
import { personDataService } from "../../../services/user/PersonsService";
import { useQuery } from "@tanstack/react-query";

export default function Charts() {
  const { loading, user } = useAuth();

  const { isLoading, isError, error, data: currentCastle } = useQuery({
    queryKey: ["persons.castles.current", user?.name], 
    queryFn: () => user ? personDataService.getCurrentCastle(user.name).then(res => res.data) : Promise.reject("User not defined"),
    enabled: !!user, 
  });

  if (loading || isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <div>Error: {error.message}</div>;
  }
  return (
    <div>
      <ElectricityAnalysisChart loading={loading || isLoading} currentCastle={currentCastle} />
      <WaterAnalysisChart loading={loading || isLoading} currentCastle={currentCastle} />
    </div>
  );
}
