import React from "react";
import Sidebar from "../../misc/Sidebar";
import { Box } from "@mui/material";
import AddCastleModal from "../../castle/add/AddCastleModal";

export default function DashboardSidebar() {
  return (
    <Sidebar
      name={"Actions"}
      elements={
        <Box sx={{ mt: 1, p: 1 }} >
          <AddCastleModal />
        </Box>
      }
    />
  );
}
