import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import HomeIcon from "@mui/icons-material/Home";
import UserMenu from "./UserMenu";
import { useAuth } from "../../context/AuthContext";
import DrawerMenu from "./mobile/DrawerMenu";
import DesktopMenuItems from "./desktop/DesktopMenuItems";

function Navbar() {
    const { user, isAuthenticated, logout } = useAuth();

    return (
        <AppBar
            position="static"
            sx={{
                marginBottom: "30px",
                height: { xs: 64, md: 90 }, // Responsive height: 64px for mobile, 80px for desktop
            }}
        >
            <Container maxWidth="xl">
                <Toolbar disableGutters sx={{ minHeight: { xs: 64, md: 80 } }}> {/* Ensure Toolbar matches AppBar height */}
                    <HomeIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} />
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: { xs: "none", md: "flex" },
                            fontFamily: "monospace",
                            fontWeight: 700,
                            letterSpacing: ".3rem",
                            color: "inherit",
                            textDecoration: "none",
                        }}
                    >
                        HAUSHALT
                    </Typography>
                    {isAuthenticated && (
                        <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                            <DrawerMenu navbarHeight={64} /> {/* Pass the height as a prop */}
                        </Box>
                    )}
                    <HomeIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} />
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: { xs: "flex", md: "none" },
                            flexGrow: 1,
                            fontFamily: "monospace",
                            fontWeight: 700,
                            letterSpacing: ".3rem",
                            color: "inherit",
                            textDecoration: "none",
                        }}
                    >
                        HAUSHALT
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                        <DesktopMenuItems isAuthenticated={isAuthenticated} />
                    </Box>
                    <UserMenu isAuthenticated={isAuthenticated} user={user} logout={logout} />
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default Navbar;
