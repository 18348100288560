import React, { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { TextField, Button, Stack, Box } from "@mui/material";
import { waterService } from './../../../services/castle/WaterService';

export default function QuickAddWater({ readingDate, currentCastle }) {
  const queryClient = useQueryClient();

  const [coldReading, setColdReading] = useState();
  const [warmReading, setWarmReading] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!coldReading && warmReading) {
      return;
    }

    try {
      quickAddWaterMutation.mutateAsync({
        readingDate: readingDate,
        coldWater: coldReading,
        warmWater: warmReading,
        castle: currentCastle
      });

      setColdReading("");
      setWarmReading("");
    } catch (error) {}
  };

  const quickAddWaterMutation = useMutation({
    mutationFn: (input) => {
      waterService.save(input);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("water.readings.table.data");
      await queryClient.invalidateQueries("water.readings.information");
    },
  });

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
      <Stack spacing={2} direction="row" sx={{ marginBottom: 4 }}>
        <TextField
          type="text"
          variant="outlined"
          label="Cold Water"
          onChange={(e) => setColdReading(e.target.value)}
          value={coldReading}
          fullWidth
          required
        />
        <TextField
          type="text"
          variant="outlined"
          label="Warm Water"
          onChange={(e) => setWarmReading(e.target.value)}
          value={warmReading}
          fullWidth
          required
        />
      </Stack>
      <Button fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} type="submit">
        Quick Add
      </Button>
    </Box>
  );
}
