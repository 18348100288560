import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { waterService } from "../../../services/castle/WaterService";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
} from "@mui/material";
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import NoReading from "../NoReading";

export default function WaterInfo() {
  const { isPending, error, data } = useQuery({
    queryKey: ["water.readings.information"],
    queryFn: () => waterService.getLatest().then((res) => res.data),
  });

  const [isData, setIsData] = useState(false);

  useEffect(() => {
    const fetchData = () => {
      if (data != null) {
        setIsData(true);
      } else {
        setIsData(false);
      }
    };

    fetchData();
  }, [data]);

  if (!isData || isPending || error) {
    return <NoReading />;
  }

  if (isData) {
    return (
      <List component="nav" aria-label="debt list">
        <ListItem>
          <ListItemIcon>
            <LibraryBooksIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="subtitle1">
                {data.readingDate} - {data.coldWater} cold water,  {data.warmWater} warm water
              </Typography>
            }
          />
        </ListItem>
      </List>
    );
  }
}
