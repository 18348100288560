import { useEffect } from "react";
import { useAuth } from "./context/AuthContext";
import { useNavigate } from "react-router-dom";

function LandingPage() {
  const { isAuthenticated, loading } = useAuth();
  let navigate = useNavigate();

  useEffect(() => {
    if (!loading) {
      if (isAuthenticated) {
        navigate(`/home`);
      } else {
        navigate(`/login`);
      }
    }
  }, [loading, isAuthenticated]);
}

export default LandingPage;
