import React, { useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { splitService } from "../../services/splitsies/SplitService";
import { useTheme } from "@mui/material/styles";
import ListItemWithTab from "../misc/ListItemWithTab";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";

export default function OwedItem({ item }) {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const [settled, setSettled] = useState(false);

  const settleMutation = useMutation({
    mutationFn: (item) => {
      return splitService.settle({
        userIds: [item.person, item.ownedTo],
        month: item.month,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("splitsies.table.data");
      await queryClient.invalidateQueries("splitsies.information");
    },
  });

  const handleCheckboxChange = async () => {
    await settleMutation.mutateAsync(item);
    setSettled(true);
  };

  if (settled) {
    return null; // Hide the row completely when settled
  }

  return (
    <ListItemWithTab
      key={item.month}
      category={item.month}
      content={
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }} align="center">Pays</TableCell>
                <TableCell sx={{ fontWeight: "bold" }} align="center">Gets Paid</TableCell>
                <TableCell sx={{ fontWeight: "bold" }} align="center">Amount</TableCell>
                <TableCell sx={{ fontWeight: "bold" }} align="center">Settle</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{
                  '&:last-child td, &:last-child th': { borderBottom: 0 },
                  textDecoration: settled ? "line-through" : "none",
                  opacity: settled ? 0.5 : 1,
                }}
              >
                <TableCell align="center">{item.person}</TableCell>
                <TableCell align="center">{item.ownedTo}</TableCell>
                <TableCell align="center">€{item.amount.toLocaleString()}</TableCell>
                <TableCell align="center">
                  <Checkbox
                    checked={settled}
                    onChange={handleCheckboxChange}
                    icon={<MonetizationOnIcon />}
                    checkedIcon={<MonetizationOnIcon />}
                    sx={{
                      transform: "scale(1.5)",
                      color: theme.palette.primary.main,
                      '&.Mui-checked': {
                        color: theme.palette.primary.main,
                      },
                    }}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      }
    />
  );
}
