import { Box } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";
import { castleService } from "../../../services/castle/CastleService";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import ActionButton from "../../misc/ActionButton";

export default function AnalysisInfo() {
  const queryClient = useQueryClient();

  const analysisMutation = useMutation({
    mutationFn: castleService.triggerAnalysis,
    onSuccess: async () => {
      await queryClient.invalidateQueries("castle.water.data");
      await queryClient.invalidateQueries("castle.electricity.data");
    },
  });

  const resetMutation = useMutation({
    mutationFn: castleService.reset,
    onSuccess: async () => {
      await queryClient.invalidateQueries("castle.water.data");
      await queryClient.invalidateQueries("castle.electricity.data");
    },
  });

  return (
    <Box sx={{ mt: 1, p: 1 }}>
      <ActionButton
        text={"Refresh"}
        onClick={() => analysisMutation.mutateAsync()}
        endIcon={<RefreshIcon />}
      />
      <ActionButton
        text={"Reset"}
        onClick={() => resetMutation.mutateAsync()}
        endIcon={<DeleteIcon />}
      />
    </Box>
  );
}
