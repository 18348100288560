import React from "react";
import DashboardPage from "./DashboardPage";
import { Grid } from "@mui/material";

export default function Dashboard() {
  return (
    <Grid container>
        <DashboardPage />
    </Grid>
  );
}
