import "./App.css";
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { AuthProvider } from "./components/context/AuthContext";
import Navbar from "./components/misc/navbar/Navbar";
import LandingPage from "./components/LandingPage";
import Splitsies from "./components/splitsies/Splitsies";
import Login from "./components/user/Login";
import Signup from "./components/user/Signup";
import Water from "./components/castle/water/Water";
import Electricity from "./components/castle/electricity/Electricity";
import QuickAdd from "./components/castle/quick/QuickAdd";
import { ThemeProvider, createTheme } from "@mui/material";
import Analysis from "./components/castle/analysis/Analysis";
import Tasks from "./components/ant/Tasks";
import TaskManager from "./components/ant/TaskManager";
import Settings from "./components/user/settings/Settings";
import Dashboard from "./components/misc/Dashboard";
import AxiosInterceptorSetup from "./components/context/AxiosInterceptor";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import PrivateRoute from "./components/misc/PrivateRoute";
import Overheads from "./components/splitsies/Overheads";

function App() {
  let theme = createTheme({
    palette: {
      primary: {
        main: "#00897B",
      },
      secondary: {
        main: "#FFAB91",
      },
    },
  });


  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: true,
        refetchOnmount: false,
        refetchOnReconnect: false,
        retry: 0,
        staleTime: 5 * 1000,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <Router>
            <AxiosInterceptorSetup />
            <Navbar />
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route element={<PrivateRoute />}>
                <Route path="/home" element={<Dashboard />} />
                <Route path="/splitsies" element={<Splitsies />} />
                <Route path="/overheads" element={<Overheads />} />
                <Route path="/castle/quick" element={<QuickAdd />} />
                <Route path="/castle/water" element={<Water />} />
                <Route path="/castle/electricity" element={<Electricity />} />
                <Route path="/castle/analysis" element={<Analysis />} />
                <Route path="/tasks" element={<Tasks />} />
                <Route path="/tasks/user" element={<TaskManager />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="*" element={<Navigate to="/" />} />
              </Route>
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
            </Routes>
          </Router>
        </ThemeProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
