import instance from "../Axios";

class ItemService {

    save(data) {
        return instance.post(`/splitsies/items/`, data);
    }

    getAll() {
        return instance.get(`/splitsies/items/`);
    }

    getActive() {
        return instance.get(`/splitsies/items/active`);
    }
        
    getById(id) {
        return instance.get(`/splitsies/items/${id}`);
    }
    
    delete(id) {
        return instance.delete(`/splitsies/items/${id}`);
    }
}

export let itemService = new ItemService();