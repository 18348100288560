import React, { useState } from "react";
import ModalPage from "../../misc/ModalPage";
import AddCastle from "./AddCastle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ActionButton from "../../misc/ActionButton";

export default function AddCastleModal() {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <ModalPage
        isOpened={isOpen}
        handleClose={() => setIsOpen(false)}
        page={<AddCastle onSubmit={() => setIsOpen(false)}/>}
      />
      <ActionButton text={"Create Castle"} onClick={() => handleOpen()} endIcon={<AddCircleIcon />} />
    </>
  );
}
