import React, { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { TextField, Button, Stack, Box } from "@mui/material";
import { electricityService } from "../../../services/castle/ElectricityService";

export default function QuickAddElectricity({ readingDate, currentCastle }) {
  const queryClient = useQueryClient();

  const [reading, setReading] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      quickAddElectricityMutation.mutateAsync({
        readingDate: readingDate,
        reading: reading,
        castle: currentCastle
      });

      setReading("");
    } catch (error) {}
  };

  const quickAddElectricityMutation = useMutation({
    mutationFn: (input) => {
      electricityService.save(input);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("electricity.readings.table.data");
      await queryClient.invalidateQueries("electricity.readings.information");
    },
  });

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
      <Stack spacing={2} direction="row" sx={{ marginBottom: 4 }}>
        <TextField
          type="text"
          variant="outlined"
          label="kWh Reading"
          onChange={(e) => setReading(e.target.value)}
          value={reading}
          fullWidth
          required
        />
      </Stack>
      <Button fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} type="submit">
        Quick Add
      </Button>
    </Box>
  );
}
