import React from "react";
import { Button } from "@mui/material";

export default function ActionButton({ text, onClick, endIcon }) {
  return (
    <Button variant="contained" onClick={onClick} endIcon={endIcon} fullWidth sx={{ m: 1 }}>
      {text}
    </Button>
  );
}
