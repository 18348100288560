import instance from "../Axios";

class WaterService {
  save(data) {
    return instance.post(`/castle/water/`, data);
  }

  getAll() {
    return instance.get(`/castle/water/`);
  }

  getLatest() {
    return instance.get(`/castle/water/latest`);
  }

  delete(data) {
    return instance.delete(`/castle/water/`, { data: data });
  }
}

export let waterService = new WaterService();
