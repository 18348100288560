import React from "react";
import CastleIcon from "@mui/icons-material/Castle";
import WaterDropIcon from "@mui/icons-material/WaterDrop";
import TungstenIcon from "@mui/icons-material/Tungsten";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import AddIcon from "@mui/icons-material/Add";
import CollapseDrawerMenu from "./CollapseDrawerMenu";

export default function CastleCollapseDrawerMenu({ toggleDrawer }) {
  const menuItems = [
    { name: "Quick Add", link: "/castle/quick", icon: <AddIcon /> },
    { name: "Water", link: "/castle/water", icon: <WaterDropIcon /> },
    { name: "Electricity", link: "/castle/electricity", icon: <TungstenIcon /> },
    { name: "Analysis", link: "/castle/analysis", icon: <TextSnippetIcon /> }
  ];

  return (
    <CollapseDrawerMenu
      icon={<CastleIcon />}
      text="Castle"
      menuItems={menuItems}
      toggleDrawer={toggleDrawer}
    />
  );
}
