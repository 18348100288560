import React from "react";
import HouseSidingIcon from "@mui/icons-material/HouseSiding";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CollapseDrawerMenu from "./CollapseDrawerMenu";

export default function TasksCollapseDrawerMenu({ toggleDrawer }) {
  const menuItems = [
    { name: "My Tasks", link: "/tasks/user", icon: <AssignmentIcon /> },
    { name: "All Tasks", link: "/tasks", icon: <HouseSidingIcon /> }
  ];

  return (
    <CollapseDrawerMenu
      icon={<HouseSidingIcon />}
      text="Tasks"
      menuItems={menuItems}
      toggleDrawer={toggleDrawer}
    />
  );
}
