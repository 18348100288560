import { Grid } from "@mui/material";
import React from "react";
import TasksList from "../ant/schedule/TasksList";
import { useQuery } from "@tanstack/react-query";
import { taskService } from "../../services/schedule/TaskService";
import { splitService } from "../../services/splitsies/SplitService";
import { useAuth } from "../context/AuthContext";
import Loading from "../misc/Loading";
import _ from "lodash";
import OwedList from "../splitsies/OwedList";
import OverheadCancelledNotification from "../splitsies/OverheadCancelledNotification";
import { overheadsService } from "../../services/splitsies/OverheadsService";

export default function DashboardPage() {

  const { user, isAuthenticated } = useAuth();

  const { isLoading: isLoadingDueForPersonData, data: dueForPersonData } = useQuery({
    queryKey: ["tasks", user.personName],
    queryFn: () => taskService.getTasksDueThisWeekForPerson(user.personName).then((res) => res.data),
    enabled: isAuthenticated
  });

  const { isLoading: isLoadingOverdues, data: overduesData } = useQuery({
    queryKey: ["tasks.overdue"],
    queryFn: () => taskService.getOverdues().then((res) => res.data),
    enabled: isAuthenticated
  });

  const { isLoading: isLoadingOwedList, data: owedData } = useQuery({
    queryKey: ["splitsies.information"], 
    queryFn: () => splitService.split().then((res) => res.data.owned),
    enabled: isAuthenticated
  });

  const { isLoading: isLoadingOverheadsCancelled, data: cancelledOverheads } = useQuery({
    queryKey: ["overheads.cancelled.information"], 
    queryFn: () => overheadsService.getCancelled().then((res) => res.data),
    enabled: isAuthenticated
  });

  if (_.isEmpty(user)) {
    return <Loading />
  }

  return (
    <>
      <Grid item xs={12} md={6} >
        <TasksList title={"Overdue Tasks"} user={user} isLoading={isLoadingOverdues} data={overduesData} />
      </Grid>
      <Grid item xs={12} md={6}>
        <TasksList title={"Current Tasks"} user={user} isLoading={isLoadingDueForPersonData} data={dueForPersonData} />
      </Grid>
      <Grid item xs={12} md={6}>
        <OwedList title={"Splitsies"} isLoading={isLoadingOwedList} data={owedData} />
      </Grid>
      <Grid item xs={12} md={6}>
        <OverheadCancelledNotification title={"Overhead Changes"} isLoading={isLoadingOverheadsCancelled} data={cancelledOverheads} />
      </Grid>
    </>
  );
}
