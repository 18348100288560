import React from "react";
import { Paper, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export default function ListItemWithTab({ category, content }) {
  const theme = useTheme();

  return (
    <Paper
      sx={{
        position: "relative",
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        boxShadow: theme.shadows[2],
        borderRadius: "10px",
        margin: "8px",
        padding: "12px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        minHeight: "100px",
        border: `1px solid ${theme.palette.divider}`,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          padding: "6px 12px",
          borderRadius: "0 0 10px 0",
          fontWeight: "600",
          fontSize: "14px",
          fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        }}
      >
        {category}
      </Box>

      <Box sx={{ marginTop: 2 }} >
        {content}
      </Box>
    </Paper>
  );
}
