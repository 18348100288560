import React from "react";
import { IconButton, Drawer, List, Divider } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import DashboardIcon from "@mui/icons-material/Dashboard";
import MobileNavbarButton from "./MobileNavbarButton";
import CastleCollapseDrawerMenu from "./CastleCollapseDrawerMenu";
import TasksCollapseDrawerMenu from "./TasksCollapseDrawerMenu";
import { useTheme } from '@mui/material/styles';
import SplitsiesCollapseDrawerMenu from "./SplitsiesCollapseDrawerMenu";

export default function DrawerMenu({ navbarHeight }) {
    const theme = useTheme();

    const [drawerOpen, setDrawerOpen] = React.useState(false);

    const toggleDrawer = (open) => (event) => {
        if (
            event &&
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }
        setDrawerOpen(open);
    };

    return (
        <>
            <IconButton
                size="large"
                aria-label="open drawer"
                onClick={toggleDrawer(true)}
            >
                <MenuIcon sx={{ color: "white" }} />
            </IconButton>
            <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
                sx={{
                    "& .MuiDrawer-paper": {
                        width: 280,
                        backgroundColor: theme.palette.background.default,
                        marginTop: `${navbarHeight}px`,
                        height: `calc(100% - ${navbarHeight}px)`,
                    },
                }}
            >
                <MobileNavbarButton
                    name={"Dashboard"}
                    link={"/home"}
                    toggleDrawer={toggleDrawer}
                    icon={<DashboardIcon />}
                />
                <Divider />
                <List
                    sx={{
                        width: "100%",
                        maxWidth: 360,
                        bgcolor: "background.paper"
                    }}
                    aria-labelledby="nested-list-subheader"
                >
                    <SplitsiesCollapseDrawerMenu toggleDrawer={toggleDrawer} />
                    <Divider />
                    <TasksCollapseDrawerMenu toggleDrawer={toggleDrawer} />
                    <Divider />
                    <CastleCollapseDrawerMenu toggleDrawer={toggleDrawer} />
                    <Divider />
                </List>
            </Drawer>
        </>
    );
}
