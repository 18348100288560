import * as React from "react";
import { Button, Menu } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CastleDropdownMenuItem from "./CastleDropdownMenuItem";

export default function DropdownNavbarButton({ name, icon, menuItems }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [menuOpen, setMenuOpen] = React.useState(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setMenuOpen(true);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setMenuOpen(false);
    };

    return (
        <>
            <Button
                variant="contained"
                color="primary"
                endIcon={<ArrowDropDownIcon />}
                onClick={handleClick}
                sx={{
                    margin: 1,
                }}
            >
                {icon}
                {name}
            </Button>
            <Menu anchorEl={anchorEl} open={menuOpen} onClose={handleClose}>
                {menuItems.map(({ name, link, icon }, index) => (
                    <CastleDropdownMenuItem
                        key={index}
                        name={name}
                        link={link}
                        closeMenu={handleClose}
                        icon={icon}
                    />
                ))}
            </Menu>
        </>
    );
}

