import * as React from "react";
import { Link } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";

export default function CastleDropdownMenuItem({ name, link, closeMenu, icon }) {
    return (
        <MenuItem component={Link} to={link} onClick={closeMenu}>
            {icon}
            {name}
        </MenuItem>
    );
}