import React from "react";
import AllDone from './AllDone';
import Loading from "../misc/Loading";
import ItemWithHeader from "../misc/ItemWithHeader";
import _ from "lodash";
import OwedOverhead from "./OwedOverhead";

export default function OwedOverheadList({ title, isLoading, data }) {

  if (isLoading) {
      return <Loading />
  }

  const elements = data?.map((item) => (
      <OwedOverhead item={item} />
  ))

  return (
      <ItemWithHeader
          title={title}
          emptyElement={<AllDone text={"Everything settled."}/>}
          elementsNotPresent={_.isEmpty(data)}
          elements={elements}
      />
  );
}
