import React from "react";
import AllDone from './AllDone';
import Loading from "../misc/Loading";
import OwedItem from "./OwedItem";
import ItemWithHeader from "../misc/ItemWithHeader";
import _ from "lodash";

export default function OwedList({ title, isLoading, data }) {

  if (isLoading) {
      return <Loading />
  }

  const elements = data?.map((item) => (
      <OwedItem item={item} />
  ))

  return (
      <ItemWithHeader
          title={title}
          emptyElement={<AllDone text={"Everything settled."}/>}
          elementsNotPresent={_.isEmpty(data)}
          elements={elements}
      />
  );
}
