import React, { useEffect, useState } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useTheme } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import { extractStringToArray } from "../misc/Helper";

export default function MultiSelect({ data = [], value, error, helperText, onChange }) {
  const [selectedValues, setSelectedValues] = useState([]);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (value != null) {
      const selected = extractStringToArray(value);
      setSelectedValues(selected);
    }
    if (data !== undefined) {
      setOptions(data);
    }
  }, [value, data]);

  const theme = useTheme();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

  function getStyles(name, selectedValues, theme) {
    return {
      fontWeight:
        selectedValues.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  return (
    <div>
      <FormControl
        fullWidth
        sx={{
          minHeight: "56px", // Matches the default height of MUI TextField
        }}
      >
        <Select
          labelId="multiSelect"
          id="multiSelect"
          error={error}
          multiple
          value={selectedValues}
          onChange={(event) => {
            const { value } = event.target;
            setSelectedValues(value);
            onChange(value);
          }}
          input={<OutlinedInput />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((it) => (
                <Chip key={it} label={it} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
          sx={{
            height: "56px", // Matches the height of the standard TextField
            display: "flex",
            alignItems: "center", // Align chips vertically in the middle
          }}
        >
          {options.map((name) => (
            <MenuItem key={name} value={name} style={getStyles(name, selectedValues, theme)}>
              {name}
            </MenuItem>
          ))}
        </Select>
        {helperText && (
          <Box sx={{ color: error ? "red" : "gray", mt: 1 }}>{helperText}</Box>
        )}
      </FormControl>
    </div>
  );
}
